
import {
  Component, Watch, Vue,
} from 'vue-property-decorator'
import { mapState } from 'vuex'
import _ from 'lodash'

import store from '@/store'
import { l, localeFromPath } from '@/utils/helpers'

import Hero from '@/components/molecules/Hero.vue'

import conf from '../../configuration'

@Component({
  components: {
    Hero,
  },
  methods: {
    l,
    handleClick(id:string) {
      const elem = document.getElementById(id)
      if (elem) {
        window.scrollTo(0, elem.offsetTop)
      }
    },
  },
  // This is used by the Vue Meta package
  // to add dynamic meta information to HTML head
  // See also public/index.html
  metaInfo() {
    const title = 'News'
    const description = 'News and updates from the Exposing The Invisible project'
    const image = conf.heroImage
    const locale = localeFromPath(this.$route.path || '/')
    return {
      title,
      meta: [
        { name: 'description', content: description },

        // Open graph
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        {
          property: 'og:image',
          content: `${conf.media.baseUrl}${conf.media.sourcePath}/${conf.media.namespace}${_.startsWith(image, '/') ? image : `/${image}`}`,
        },

        // Twitter
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:summary', content: description },
        { property: 'twitter:title', content: title },
        { property: 'twitter:description', content: description },
        { property: 'twitter:url', content: window.location.href },
        {
          property: 'twitter:image',
          content: `${conf.media.baseUrl}${conf.media.sourcePath}/${conf.media.namespace}${_.startsWith(image, '/') ? image : `/${image}`}`,
        },
      ],
      htmlAttrs: {
        lang: locale,
      },
      // Remove the /en/ part from the canonical URL if the language is English
      // Make sure canonical URLs always end with a /
      link: [
        {
          rel: 'canonical',
          href: locale === 'en' && !this.$route.path.includes('/en/')
            ? `${conf.url}${_.replace(_.endsWith(this.$route.path, '/') ? this.$route.path : `${this.$route.path}/`, '/', '/en/')}`
            : `${conf.url}${_.endsWith(this.$route.path, '/') ? this.$route.path : `${this.$route.path}/`}`,
        },
      ],
    }
  },
  computed: mapState({
    news: (state: any) => (
      state.content ? state.content.news : undefined
    ),
    landingPage: (state: any) => (
      state.active ? state.active.landingPage : undefined
    ),
  }),
})
export default class NewsOverviewPage extends Vue {
  constructor() {
    super()
    this.locale = ''
  }

  private locale: string

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.locale = localeFromPath(this.$route.path || '/')
    try {
      if (_.isEmpty(this.$store.getters.news)) {
        try {
          store.dispatch(
            'fetch',
            `/?contentType=news&&locale=${this.locale}`,
          )
            .then((result: any) => {
              if (result) {
                try {
                  // Set all News in the VUEX store
                  store.dispatch('set', { key: 'content.news', data: result })
                } catch (err) {
                  document.dispatchEvent(new Event('custom-render-trigger'))
                }
                return result
              }
              return undefined
            })
            .then(() => {
              try {
                // Get landingpage from uuid
                store.dispatch(
                  'fetch',
                  `/?slug=news&&contentType=landingPage&&locale=${this.locale}`,
                )
                  .then((result: any) => {
                    if (result) {
                      try {
                        // Set active landingPage in the VUEX store
                        store.dispatch('set', { key: 'active.landingPage', data: result[0] })

                        /* Tell the prerender-spa-plugin that data fetching is complete
                        * and that prerendering can start, see vue.config.js.
                        * Wait until all the stores variables are set and give
                        * the render engine some time to render.
                        */
                        if (this.$store.state
                            && this.$store.state.active
                            && this.$store.state.active.landingPage
                            && this.$store.state.content
                            && this.$store.state.content.news) {
                          setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 10000)
                        }
                      } catch (err) {
                        document.dispatchEvent(new Event('custom-render-trigger'))
                      }
                      return result[0]
                    }
                    return undefined
                  })
              } catch (err) {
                document.dispatchEvent(new Event('custom-render-trigger'))
              }
            })
        } catch (err) {
          document.dispatchEvent(new Event('custom-render-trigger'))
        }
      }
    } catch (err) {
      //
    }
  }

  sortedNews(news: any[]) {
    if (news) {
      return _.groupBy(news, (item: any) => item.publicationDate.substring(0, 4))
    }
    return []
  }
}
