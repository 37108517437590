

import {
  Component, Watch, Vue, Prop,
} from 'vue-property-decorator'
import store from '@/store'

@Component({
  data: () => ({
    content: {},
  }),
})
export default class Label extends Vue {
  @Prop({ }) private contentObject!: object

  @Prop({ }) private uuid!: string

  private content!: Object

  @Watch('contentObject')
  onContentObjectChange(newContentObject: any, oldContentObject: any) {
    this.content = newContentObject
  }

  @Watch('uuid')
  onUuidChange(newUuid: string, oldUuid: string) {
    this.uuid = newUuid
    this.fetchData()
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    if (this.contentObject) this.content = this.contentObject
    // Fetch from the API if a UUID is passed as props
    // and if no contentObject prop is present
    if (this.uuid && !this.contentObject) {
      this.fetchData()
    }
  }

  fetchData() {
    if (this.uuid) {
      try {
        store.dispatch(
          'fetch',
          `/?uuid=${this.uuid}`,
        )
          .then((result: any) => {
            if (result) {
              this.$nextTick(() => {
                this.content = result
              })
            } else {
              // console.log(result)
            }
          })
          .catch(
            (error: string) => {
              // throw new Error(error)
            },
          )
      } catch (error) {
        // console.log(error)
      }
    }
  }
}
