
import { Component, Vue } from 'vue-property-decorator'
import { l, localeFromPath } from '@/utils/helpers'
import ResponsiveImage from '@/components/atoms/ResponsiveImage.vue'


@Component({
  components: {
    ResponsiveImage,
  },
  data() { return { hoverSticker: false } },
  methods: {
    l,
  },
})
export default class Sticker extends Vue {
  get locale() {
    return localeFromPath(this.$route.path)
  }
}
