

import {
  Component, Vue, Watch, Prop,
} from 'vue-property-decorator'
import { mapState } from 'vuex'
import _ from 'lodash'
import FilterButton from '@/components/atoms/FilterButton.vue'
import Loader from '@/components/atoms/Loader.vue'
import store from '@/store'
import SvgIcon from '@/components/atoms/SvgIcon.vue'
import {
  l, localeFromPath, contentTypeSegmentFromUrl, themeSegmentFromUrl,
} from '@/utils/helpers'

@Component({
  components: {
    FilterButton,
    Loader,
    SvgIcon,
  },
  data() { return { hoverActive: '' } },
  computed: mapState({
    // Tags which are set as active filters
    activeTags: (state: any) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      ((state.active && state.active.theme && state.active.theme.tags)
        ? state.active.theme.tags.active
        : undefined
      ),
    // All Tags for this Theme
    passiveTags: (state: any) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      ((state.active && state.active.theme && state.active.theme.tags)
        ? state.active.theme.tags.passive
        : undefined
      ),
    // All Topics which are set as active filters
    activeTopics: (state: any) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      ((state.active && state.active.theme && state.active.theme.topics)
        ? state.active.theme.topics.active
        : undefined
      ),
    // All topics for this theme
    passiveTopics: (state: any) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      ((state.active && state.active.theme && state.active.theme.topics)
        ? state.active.theme.topics.passive
        : undefined
      ),
  }),
  methods: {
    l,
  },
})

export default class FilterBar extends Vue {
  @Prop({ default: false }) private hasFilters!: boolean

  constructor() {
    super()
    this.isFiltering = false
  }


  private isFiltering: boolean

  private routes = {}

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    if (this.$route) {
      try {
        // Fetch the Theme from the API based on its slug
        store.dispatch(
          'fetch',
          `/?slug=${themeSegmentFromUrl()}&&locale=${this.locale}`,
        )
          .then((result: any) => {
            if (result && result[0]) {
              // Set the fetched Theme as active in the VUEX store
              try {
                store.dispatch('set', { key: 'active.theme.theme', data: result[0] })
              } catch (error) {
                document.dispatchEvent(new Event('custom-render-trigger'))
              }
            }
          })
          .catch(
            (error: string) => {
              try {
                store.dispatch('set', { key: 'status', data: 404 })
              } catch (err) {
                document.dispatchEvent(new Event('custom-render-trigger'))
              }
            },
          )
      } catch (error) {
        document.dispatchEvent(new Event('custom-render-trigger'))
      }
    }
  }

  isActive(uuid: string) {
    if (
      _.find(
        _.concat(this.$store.getters.activeTags, this.$store.getters.activeTopics),
        (t: any) => t.uuid === uuid,
      )
    ) return true
    return false
  }

  toggleFilter(filter: any) {
    this.isFiltering = true
    if (this.isActive(filter.uuid)) {
      this.deactivateFilter(filter)
      store.dispatch('filterContent')
    } else {
      this.activateFilter(filter)
      store.dispatch('filterContent')
    }
    setTimeout(() => {
      this.isFiltering = false
    }, 2000)
  }

  activateFilter(filter: any) {
    try {
      store.dispatch('add', { key: 'active.theme.tags.active', data: filter })
    } catch (err) {
      // console.log(err)
    }
  }

  deactivateFilter(filter: any) {
    try {
      store.dispatch('removeByUuid', { key: 'active.theme.tags.active', uuid: filter.uuid })
    } catch (err) {
      // console.log(err)
    }
  }

  get locale() {
    return localeFromPath(this.$route.path)
  }

  get theme() {
    return this.$store.getters.activeTheme
  }

  checkActiveMainFilter(route: string) {
    return contentTypeSegmentFromUrl() === route
  }

  checkIfEducateHome() {
    const routeArr = this.$route.fullPath.split('/').filter(r => r.length)
    return routeArr[routeArr.length - 1] === 'learn'
  }
}
