
import { Vue, Component, Prop } from 'vue-property-decorator'
import ResponsiveImage from '@/components/atoms/ResponsiveImage.vue'
import Label from '@/components/atoms/Label.vue'
// import animeImg from '@/assets/svg/anime.svg'
import FilterBar from '@/components/molecules/FilterBar.vue'
import Sticker from '@/components/atoms/Sticker.vue'

import SvgIcon from '@/components/atoms/SvgIcon.vue'

@Component({
  components: {
    ResponsiveImage,
    Label,
    FilterBar,
    SvgIcon,
    Sticker,
  },
})
export default class Hero extends Vue {
  @Prop({ required: true }) private imgUrl!: string

  @Prop({ required: true }) private imgAlt!: string

  @Prop({ required: true }) private heading!: string

  @Prop() private subHeading!: string

  @Prop({ }) private topicUuids!: Array<any>

  @Prop({ }) private platforms!: Array<any>

  @Prop({ default: false }) private hasFilterBar!: boolean
}
